import _ from 'lodash';
import HttpUtil from './HttpUtil';

/**
 * promise http方法
 * @param {*} settings {...} HttpUtil fetch入参
 * @param {*} settings.customErrMsg 自定义错误描述
 * @param {*} settings.isCustomErrHandle 自定义错误处理
 * @param {*} settings.notShowDescRetcodes 集合中的code将不会有错误消息提示，还有response返回
 * 
 */
function fetch(settings) {
  const { customErrMsg, isCustomErrHandle, notShowDescRetcodes } = settings;
  return new Promise((resolve, reject) => {
    function success(response) {
      const { retcode, retdesc } = response;
      if(notShowDescRetcodes && notShowDescRetcodes.indexOf(retcode) !== -1){
        reject(response);
      }else if (retcode !== 0 && isCustomErrHandle) {
        throw new Error(retdesc);
      } else if (retcode !== 0 && !isCustomErrHandle) {
        HttpUtil.showToast('warn', retdesc);
        reject(response);
      } else {
        resolve(response);
      }
    }
    function error(e) {
      reject(e);
      if (isCustomErrHandle) {
        return;
      }
      HttpUtil.showToast('warn', _.trim(customErrMsg) === '' ? `${_.trim(e.message || e)}` : customErrMsg);
    }
    HttpUtil.fetch({ ...settings, success, error });
  });
}

export default {
  ...HttpUtil,
  fetch,
};
