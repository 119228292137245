const systemConfigRouter = [
  {
    path: 'groupParamsMapping',
    name: 'groupParamsMapping',
    meta: {
      permissionCode: 'cp-group-hotelParamMapping',
      routerType: 2,
    },
    component: () => import('../views/group-management/hotel-mapping/group-params-mapping/index.vue'),
  },
];

const channelRouter = [
  {
    // 渠道定义-渠道列表
    path: 'channelDefineIndex/list',
    name: 'channelList',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/list/index.vue'),
  },
  {
    // 渠道定义-渠道列表-门店维护
    path: 'channelDefineIndex/info/:channelId/hotels/:channelName/:systemChannelId/:switchNo',
    name: 'channelHotelList',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/hotel-list/index.vue'),
  },
  {
    // 渠道定义-渠道列表-渠道信息
    path: 'channelDefineIndex/info/:channelId?/:systemChannelId?/:switchNo?',
    name: 'channelInfo',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/info/index.vue'),
  },
  {
    // 渠道定义-渠道酒店列表
    path: 'channelHotelListManage',
    name: 'channelHotelListManage',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/channel-hotel-list-manage/index.vue'),
  },
  {
    // 定价策略
    path: 'strategyControl',
    name: 'strategyControl',
    meta: {
      permissionCode: 'cp-strategyControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/strategy-control/index.vue'),
    children: [
      {
        // 渠道结算价-预付 定价策略（低价）
        path: 'pricingStrategy',
        name: 'pricingStrategy',
        meta: {
          permissionCode: 'cp-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-manage/pricing-strategy/index.vue'),
      },
      {
        // 渠道卖价-预付 定价策略（底卖价）
        path: 'sellPricingStrategy',
        name: 'sellPricingStrategy',
        meta: {
          permissionCode: 'cp-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-manage/pricing-strategy/index.vue'),
      },
      {
        // 直销散客结算价
        path: 'directSellPricingStrategy',
        name: 'directSellPricingStrategy',
        meta: {
          permissionCode: 'cp-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-manage/direct-sellpricing-strategy/index.vue'),
      },
      {
        // 酒店佣金-现付
        path: 'hotelCommissionConfig',
        name: 'hotelCommissionConfig',
        meta: {
          permissionCode: 'cp-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-manage/hotel-commission-config/index.vue'),
      },
      {
        // 渠道卖价-现付
        path: 'channelSellPriceConfig',
        name: 'channelSellPriceConfig',
        meta: {
          permissionCode: 'cp-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-manage/channel-sell-price-config/index.vue'),
      },
      {
        // 渠道佣金-现付
        path: 'channelCommissionConfig',
        name: 'channelCommissionConfig',
        meta: {
          permissionCode: 'cp-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-manage/channel-commission-config/index.vue'),
      },
    ],
  },

  // 通路参数设置列表
  {
    path: 'switchParamsConfig',
    name: 'switchParamsConfig',
    meta: {
      permissionCode: 'cp-group-switchParamsConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/switch-params-config/index.vue'),
  },
  {
    // 通路参数设置详情
    path: 'switchParamsConfigInfo/:switchNo',
    name: 'switchParamsConfigInfo',
    meta: {
      permissionCode: 'cp-group-switchParamsConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/switch-params-config/switch-params-info/index.vue'),
  },
  {
    // IBE分销黑白名单配置
    path: 'IBEDistributionManage',
    name: 'IBEDistributionManage',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/IBE-distribution-manage/index.vue'),
  },
  {
    // 直连管控参数配置-证件类型
    path: 'certificateType',
    name: 'certificateType',
    meta: {
      permissionCode: 'cp-group-controlParamsConfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/control-params-config/index.vue'),
  },


  {
    // OTA匹配
    path: 'OTAMatch',
    name: 'hotelMatch',
    meta: {
      permissionCode: 'cp-hotelMatch',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/hotel-match/OTA-match/index.vue'),
    children: [
      {
        // 携程酒店匹配
        path: 'ctripHotelMapping',
        name: 'ctrip-hotel-mapping',
        meta: {
          permissionCode: 'cp-hotelMatch',
          routerType: 2,
        },
        component: () => import('../views/group-management/vest-product/ctripHotelMapping.vue'),
      },
      {
        // 途牛酒店及参数映射
        path: 'tuniuMapping',
        name: 'tuniuMapping',
        meta: {
          permissionCode: 'cp-hotelMatch',
          routerType: 2,
        },
        component: () => import('../views/group-management/vest-product/ctripHotelMapping.vue'),
      },
    ],
  },
  {
    // PMS匹配
    path: 'PMSMatch',
    name: 'PMSMatch',
    meta: {
      permissionCode: 'cp-hotelMatch',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/hotel-match/PMS-match/index.vue'),
    children: [
      {
        // 酒店映射
        path: 'pmsHotelMapping/:hotelId?',
        name: 'el-group-ui-hotelMapping',
        meta: {
          permissionCode: 'cp-hotelMatch',
          routerType: 2,
        },
        component: () => import('../views/group-management/hotel-mapping/pms-hotel-mapping.vue'),
      },
      {
        // 房型映射
        path: 'pmsRoomMapping/:hotelId?',
        name: 'el-group-ui-roomTypeMapping',
        meta: {
          permissionCode: 'cp-hotelMatch',
          routerType: 2,
        },
        component: () => import('../views/group-management/hotel-mapping/pms-room-mapping.vue'),
      },
    ],
  },
  {
    // AmadeusMatch匹配
    path: 'AmadeusMatch',
    name: 'AmadeusMatch',
    meta: {
      permissionCode: 'cp-hotelMatch',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/hotel-match/Amadeus-match/index.vue'),
    children: [
      {
        // 酒店及房型
        path: 'AmadeuslMapping',
        name: 'AmadeuslMapping',
        meta: {
          permissionCode: 'cp-hotelMatch',
          routerType: 2,
        },
        component: () => import('../views/group-management/vest-product/amadeuslMapping.vue'),
      },
      {
        // commission
        path: 'commissionMapping',
        name: 'commissionMapping',
        meta: {
          permissionCode: 'cp-hotelMatch',
          routerType: 2,
        },
        component: () => import('../views/group-management/vest-product/amadeuslMapping.vue'),
      },
    ],
  },
  {
    // 房态房量维护
    path: 'channelRoomState',
    name: 'channelRoomState',
    meta: {
      permissionCode: 'cp-group-channelRate',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/room-state/index.js'),
  },
  {
    // 定制渠道房量
    path: 'customRoomState',
    name: 'customRoomState',
    meta: {
      permissionCode: 'cp-group-channelRate',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-manage/room-state/custom-room-state/index.vue'),
  },

];
// CRS2.0
const crs2Router = [
  {
    // 策略管控
    path: 'strategyControNew',
    name: 'strategyControNew',
    meta: {
      permissionCode: 'cp-group-strategyControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/strategy-control/strategy-control/index.vue'),
    children: [
      {
        path: 'hotelStrategyControl',
        name: 'hotelStrategyControl',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/strategy-control/hotel-settlement-config/index.vue'),
      },
      {
        path: 'channelStrategyControl',
        name: 'channelStrategyControl',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/strategy-control/channel-settlement-config/index.vue'),
      },
      {
        // 酒店佣金-现付
        path: 'hotelCommissionConfigNew',
        name: 'hotelCommissionConfigNew',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/strategy-control/hotel-commission-config/index.vue'),
      },
      {
        // 渠道佣金-现付
        path: 'channelCommissionConfigNew',
        name: 'channelCommissionConfigNew',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/strategy-control/channel-commission-config/index.vue'),
      },
      {
        // 酒店卖价-现付
        path: 'hotelSellPriceConfig',
        name: 'hotelSellPriceConfig',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/strategy-control/hotel-sell-price-config/index.vue'),
      },
      {
        path: 'channelCancelRule',
        name: 'channelCancelRule',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-cancel-rule/channel-cancel-rule/index.vue'),
      },
      {
        path: 'hotelCancelRule',
        name: 'hotelCancelRule',
        meta: {
          permissionCode: 'cp-group-strategyControl',
          routerType: 2,
        },
        component: () => import('../views/group-management/channel-cancel-rule/hotel-cancel-rule/index.vue'),
      },
    ],
  },
  {
    // 2.0策略模板管理
    path: 'strategyTemplateRegulate',
    name: 'strategyTemplateRegulate',
    meta: {
      permissionCode: 'cp-group-strategyTemplateRegulate',
      routerType: 2,
    },
    component: () => import('../views/group-management/strategy-control/strategy-control-template/index.vue'),
  },
  {
    // 2.0开关
    path: 'crs2Switch',
    name: 'crs2Switch',
    meta: {
      permissionCode: 'cp-group-crs2Switch',
      routerType: 2,
    },
    component: () => import('../views/group-management/crs2-switch/index.vue'),
  },
  {
    // 2.0开关
    path: 'myselfCrs2Switch',
    name: 'myselfCrs2Switch',
    meta: {
      permissionCode: 'cp-group-crs2Switch',
      routerType: 2,
    },
    component: () => import('../views/group-management/crs2-switch/my-self/index.vue'),
  },
  {
    // 2.0开关-校验日志
    path: 'checkLog',
    name: 'checkLog',
    meta: {
      permissionCode: 'cp-group-crs2Switch',
      routerType: 2,
    },
    component: () => import('../views/group-management/crs2-switch/check-log/index.vue'),
  },
  {
    // 促销活动配置-酒店名单
    path: 'ctripCouponHotelList',
    name: 'ctripCouponHotelList',
    meta: {
      permissionCode: 'cp-group-crspromotionconfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/promotion-config/ctrip-coupon/hotel-list.vue'),
  },
  {
    // 促销活动配置-优惠券详情
    path: 'ctripCouponDetail',
    name: 'ctripCouponDetail',
    meta: {
      permissionCode: 'cp-group-crspromotionconfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/promotion-config/ctrip-coupon/coupon-detail.vue'),
  },
  {
    // 促销活动配置-携程优惠券
    path: 'ctripCoupon',
    name: 'ctripCoupon',
    meta: {
      permissionCode: 'cp-group-crspromotionconfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/promotion-config/ctrip-coupon/coupon-list.vue'),
  },
  {
    // 促销活动配置-携程活动
    path: 'crspromotionconfig',
    name: 'promotionlist',
    meta: {
      permissionCode: 'cp-group-crspromotionconfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/promotion-config/promotion-list.vue'),
  },
  {
    // 促销活动详情
    path: 'crspromotiondetail',
    name: 'crspromotiondetail',
    meta: {
      permissionCode: 'cp-group-crspromotionconfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/promotion-config/edit-promotion.vue'),
  },
  {
    // 促销活动-酒店名单
    path: 'crspromotionhotellist',
    name: 'crspromotionhotellist',
    meta: {
      permissionCode: 'cp-group-crspromotionconfig',
      routerType: 2,
    },
    component: () => import('../views/group-management/promotion-config/hotel-list/index.vue'),
  },
  {
    // 渠道房价
    path: 'superCrsChannelRate',
    name: 'superCrsChannelRate',
    meta: {
      permissionCode: 'cp-group-superCrsChannelRate',
      routerType: 2,
    },
    component: () => import('../views/group-management/super-crs-channel-rate/index.vue'),
  },
  {
    // 渠道房量房态
    path: 'channelRoomNumAndState',
    name: 'channelRoomNumAndState',
    meta: {
      permissionCode: 'cp-group-superCrsChannelRate',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-room-state/index.vue'),
  },
  // 批量修改渠道组的房量房态
  {
    path: 'batchEditChannelState',
    name: 'batchEditChannelState',
    meta: {
      permissionCode: 'cp-group-superCrsChannelRate',
      routerType: 2,
    },
    component: () => import('../views/group-management/channel-room-state/batch-edit-channel-state/index.vue'),
  },
];
// 丽呈定制-分销配置
const cspRouter = [
  {
    // 商旅分销管理
    path: 'cspManagement',
    name: 'cspManagement',
    meta: {
      permissionCode: 'cp-group-ctripBusinessTravelDiscountManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-csp-management/csp-management.vue'),
  },
];

// 丽呈定制-买断房
const buyOutRouter = [
  {
    path: 'buyoutRoomList',
    name: 'buyoutRoomList',
    meta: {
      permissionCode: 'cp-group-buyOutRoomManageNew',
      routerType: 2,
    },
    component: () => import('../views/group-management/buyoutroom-management/index.vue'),
  }, {
    path: 'buyoutRoomInfo/:hotelId/:hotelName',
    name: 'buyoutRoomInfo',
    meta: {
      permissionCode: 'cp-group-buyOutRoomManageNew',
      routerType: 2,
    },
    component: () => import('../views/group-management/buyoutroom-management/buyoutroom-info/index.vue'),
  },
];


// 数据监控
const dataMonitor = [
  {
    // CRS操作日志
    path: 'crsOperateLog',
    name: 'crsOperateLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/crs-operate-log/index.vue'),
  },
  {
    // 渠道操作日志
    path: 'channelLog',
    name: 'channelLog',
    meta: {
      permissionCode: 'cp-data-monitor',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/channel-log/index.vue'),
  },
  {
    // 火眼日志
    path: 'fireEyeLog',
    name: 'fireEyeLog',
    meta: {
      permissionCode: 'cp-fire-eye',
      routerType: 2,
    },
    component: () => import('../views/group-management/data-monitoring/fire-eye/index.vue'),
  },
];

// 集团活动管理
const groupActivityManageRouter = [
  {
    path: 'groupActivityManage',
    name: 'groupActivityManage',
    meta: {
      permissionCode: 'cp-groupActivityManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-activity-manage/index.vue'),
  }, {
    path: 'groupActivityManageInfo/:activityId?',
    name: 'groupActivityManageInfo',
    meta: {
      permissionCode: 'cp-groupActivityManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-activity-manage/activity-info/index.vue'),
  }, {
    path: 'groupActivityApplyProgress/:activityId',
    name: 'groupActivityApplyProgress',
    meta: {
      permissionCode: 'cp-groupActivityManage',
      routerType: 2,
    },
    component: () => import('../views/group-management/group-activity-manage/activity-apply-progress/index.vue'),
  },
];
// 价格管理-新路由
const priceManagementRouter = [
  // 酒店价格来源旧地址
  {
    path: 'lcCtripMemberLevelMapping',
    name: 'lcCtripMemberLevelMapping',
    meta: {
      permissionCode: 'cp-strategyControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/price-management-old/lc-ctrip-member-level-mapping'),
  },
  {
    path: 'hotelPriceSource',
    name: 'hotelPriceSource',
    meta: {
      permissionCode: 'cp-strategyControl',
      routerType: 2,
    },
    component: () => import('../views/group-management/price-management-old/hotel-price-source'),
  },
];

// 新改版的渠道管理
const newChannelManagementRouter = [
  {
    path: 'channelManagement/newChannelList', //改版的渠道列表
    name: 'newChannelList',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
      keepAlive: true,
    },
    component: () => import('../views/group-management/new-channel-management/channel-list/index.vue'),
  },
  {
    path: 'channelManagement/newChannelList/storechannelMaintenance', //渠道列表=》门店维护
    name: 'storechannelMaintenance',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
      isNotShowTab: true
    },
    component: () => import('../views/group-management/new-channel-management/channel-list/store-channel-maintenance.vue'),
  },
  {
    path: 'channelManagement/newChannelList/storechannelSetMaintenance', //渠道列表=》门店维护
    name: 'storechannelSetMaintenance',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
      isNotShowTab: true
    },
    component: () => import('../views/group-management/new-channel-management/channel-list/store-channelSet-maintenance.vue'),
  },
  {
    path: 'channelManagement/newHotelList', //改版的酒店列表
    name: 'newHotelList',
    meta: {
      permissionCode: 'cp-group-channeldefine',
      routerType: 2,
      keepAlive: true,
    },
    component: () => import('../views/group-management/new-channel-management/hotel-list/index.vue'),
  },
];
export default [
  ...systemConfigRouter,
  ...channelRouter,
  ...cspRouter,
  ...crs2Router,
  ...buyOutRouter,
  ...dataMonitor,
  ...groupActivityManageRouter,
  ...priceManagementRouter,
  ...newChannelManagementRouter
];
